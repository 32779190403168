import React from "react";
import Layout from "../component/Layout/Layout";
import BlogPosts from "../component/Posts/BlogPosts";
import StickyBox from "../component/StickyBox/StickyBox";
import "../assets/styles/article-page.sass";
import "../assets/styles/blog-page.sass";
import CompareWidget from "../component/CompareWidget/CompareWidget";
import RankingItem from "../component/Ranking/RankingItem";
import { getImage } from "gatsby-plugin-image";
import Heading from "../component/Heading";
import Seo from "../component/Seo/Seo";

const Company = ({pageContext}) => {
  const company = pageContext.data;
  const index = pageContext.index;
  const image = getImage(pageContext.image);
  return (
    <Layout>
      <Seo
        title={company.seo.title}
        description={company.seo.metaDesc}
        keywords={company.seo.focuskw}
        article={true}
        pathname={`/ikze/${company.slug}/`}
      />
      <div className="container mw-1400">
        <div className="row">
          <div className="col-xl-12">
            <RankingItem {...company} logo={image} index={index} showDetailsButton={false} showShadow={false}/>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8 article">
            <div className="d-block">
              <Heading
                title={company.title}
                showUnderline={false}
                maxWidth="100%"
                margin="0 0 20px 0"
                align="flex-start"
                fontSize={44}
                h={1}
              />
              <div className="line"></div>
            </div>
            <div className="content" dangerouslySetInnerHTML={{__html: company.content}}></div>
          </div>
          <div className="col-xl-4">
            <StickyBox>
              <CompareWidget isNarrow={true} />
            </StickyBox>
          </div>
        </div>
      </div>
      <BlogPosts />
    </Layout>
  );
};

export default Company;
