import React from "react";
import Button from "../Button/Button";
import Stars from "../Stars/Stars.jsx";
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";
import config from "../../../config.json";

type Ranking = {
  ocena: number;
  minimalnaWartoscWplat: number;
  oplataOdWplaty: number;
  oplataZaRozwiazanieUmowy: number;
  oplataZaWynikInwestycyjny: number;
  oplataZaZarzadzanie: number;
  stopaZwrotu: number;
  urlDoFormularza: string;
};
export interface IRankingItemProps {
  id: number;
  index: number;
  ranking: Ranking;
  slug: string;
  title: string;
  logo: IGatsbyImageData;
  showDetailsButton?: boolean;
  showShadow?: boolean;
}

const RankingItem: React.FC<IRankingItemProps> = ({
  index,
  ranking,
  slug,
  title,
  logo,
  showDetailsButton = true,
  showShadow = true,
}: IRankingItemProps) => {
  function displayValue(num: number, showFractionDigits: boolean = false) {
    if (num === null) return "0";
    if (showFractionDigits) return num.toLocaleString("pl", {minimumFractionDigits: 2});
    return "" + num;
  }

  return (
    <div className={`rank-wrapper ${showShadow && "main-shadow"}`} 
      data-mark={ranking.ocena} 
      data-rateofreturn={ranking.stopaZwrotu}
      data-investfee={ranking.oplataZaWynikInwestycyjny}
      data-menagmentfee={ranking.oplataZaZarzadzanie}
      data-contractterminationfee={ranking.oplataZaRozwiazanieUmowy}
      data-minimumdeposit={ranking.minimalnaWartoscWplat}
      >
      
      <div className="row d-flex align-items-start justify-content-center align-items-lg-center ">
        <div className="col-2 col-lg-1 pr-0 d-flex align-items-sm-start">
          <div className="position">{index}</div>
        </div>
        <div className="col-10 col-lg-11 pl-0">
          <div className="row">
            <div className="col-12 col-lg-auto name d-block d-lg-flex align-items-center">
              {title}
              <div className="mx-lg-3 mt-2 mt-lg-0">
                <Stars rating={ranking.ocena} />
              </div>
            </div>
            <div className="col d-flex align-items-center ">
              <div className="line"></div>
            </div>
            <div className="col-6 col-lg-2 logo-container d-flex align-items-center ">
              <GatsbyImage image={logo} alt={title} title={title} />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-lg-3 border-right">
            <p className="xl-text secondary">{displayValue(ranking.stopaZwrotu, true)}%</p>
            <p className="small-text">
              stopa
              <br />
              zwrotu
            </p>
          </div>
          <div className="col-6 col-lg-3 border-right">
            <p className="xl-text">{displayValue(ranking.oplataZaWynikInwestycyjny)}</p>
            <p className="small-text">
              liczba funduszy/programów
            </p>
          </div>
          <div className="col-6 col-lg-3 border-right mt-3 mt-lg-0">
            <p className="xl-text">{displayValue(ranking.oplataZaZarzadzanie, true)}%</p>
            <p className="small-text">
              opłata
              <br />
              za zarządzanie
            </p>
          </div>
          <div className="col-6 col-lg-3 mt-3 mt-lg-0">
            <p className="xl-text">{ranking.oplataOdWplaty}</p>
            <p className="small-text">
              opłata za otwarcie konta
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-6 col-lg-6 d-flex align-items-center justify-content-end">
                <div className="amount-text">opłata za rozwiązanie umowy po 1. roku</div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="xl-value">{displayValue(ranking.oplataZaRozwiazanieUmowy)}</div>
              </div>
              <div className="col-6 col-lg-6 d-flex mt-2 align-items-center justify-content-end">
                <div className="amount-text">minimalna wartość I wpłaty</div>
              </div>
              <div className="col-6 col-lg-4 mt-2">
                <div className="xl-value">{displayValue(ranking.minimalnaWartoscWplat)} zł</div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 buttons mt-4 mt-lg-0">
            {ranking.urlDoFormularza ? (
                <Button title="Zamów online" target="_blank" externalLink={ranking.urlDoFormularza} width="100%" />
            ) : (
                <Button title="Zamów online" internalLink={config.CALC_URL} width="100%" />
            )
            }
            {showDetailsButton && (
              <Button title="Zobacz szczegóły" secondary={true} internalLink={`/ikze/${slug}/`} width="100%" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankingItem;
